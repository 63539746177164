<template>
  <div>
    <div class="table-operator" style="margin-bottom: 15px">
      <a-button type="primary" icon="plus" @click="modalAddEditLoad()">添加</a-button>
      <a-button type="primary" icon="download" @click="downloadExcel()" style="float: right">下载干线表</a-button>
    </div>

    <s-table
      ref="pageTable"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="dataset"
      :pagination="pagination"
      :pageSize="10"
      :scroll="{}"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="freeState" slot-scope="text, record">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          :defaultChecked="Boolean(record.freeState)"
          @change="handleUpdateFreeState(record.id, $event)"
        />
      </span>
      <span slot="operation" slot-scope="text, record">
        <a @click="modalAddEditLoad(record)">编辑</a> |
        <a-popconfirm
          title="您确认要删除此条记录吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleRemove(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <a-modal
      key="addEdit"
      title="新增/编辑"
      :width="600"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :visible="modalAddEditVisible"
      @cancel="modalAddEditCancel"
    >
      <a-form :form="modalAddEditForm" @submit="modalAddEditSubmit">
        <a-form-item
          label="起始地"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-cascader
            name="fromGeoCode"
            placeholder="请选择起始地"
            v-decorator="['fromGeoCode', modalAddEditFormRules.fromGeoCode]"
            :options="geoCascaderList"
          />
        </a-form-item>

        <a-form-item
          label="目的地"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-cascader
            name="toGeoCode"
            placeholder="请选择目的地"
            v-decorator="['toGeoCode', modalAddEditFormRules.toGeoCode]"
            :options="geoCascaderList"
          />
        </a-form-item>

        <a-form-item
          label="一级运费单价"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="firstTransportPrice"
            :min="0"
            :step="0.01"
            v-decorator="['firstTransportPrice', modalAddEditFormRules.firstTransportPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="开启干线免费"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-switch
            name="freeState"
            :active-value="1"
            :inactive-value="0"
            checked-children="开"
            un-checked-children="关"
            v-decorator="['freeState', modalAddEditFormRules.freeState]"
          />
        </a-form-item>

        <a-form-item class="form-submit">
          <a-input name="id" type="hidden" style="display: none" v-decorator="['id', modalAddEditFormRules.id]" />
          <a-button style="margin-right: 15px" @click="modalAddEditCancel">取消</a-button>
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { STable } from '@/components'
import { arteryList, arteryInsert, arteryUpdate, arteryRemove, geoCascaderList } from '@/api/quotation'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'fromCityName',
    dataIndex: 'fromCityName',
    title: '起始地',
    align: 'center',
    width: '20%',
  },
  {
    key: 'toCityName',
    dataIndex: 'toCityName',
    title: '目的地',
    align: 'center',
    width: '20%',
  },
  {
    key: 'firstTransportPrice',
    dataIndex: 'firstTransportPrice',
    align: 'center',
    title: '一级运费单价（元）',
    width: '20%',
  },
  {
    key: 'freeState',
    dataIndex: 'freeState',
    align: 'center',
    title: '开启干线免费',
    width: '20%',
    scopedSlots: {
      customRender: 'freeState',
    },
  },
  {
    key: 'operation',
    align: 'center',
    dataIndex: 'operation',
    title: '操作',
    width: '20%',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
const modalAddEditFormRules = {
  id: { initialValue: null },
  fromGeoCode: { rules: [{ required: true, message: '请选择起始地' }] },
  toGeoCode: { rules: [{ required: true, message: '请选择目的地' }] },
  firstTransportPrice: { rules: [{ required: true, message: '请输入一级运费单价' }] },
  freeState: { initialValue: true, valuePropName: 'checked' },
}

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      columns: columns,
      dataset: (parameter) => {
        return arteryList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            if (0 == res.code) {
              return res.result
            }
          })
          .catch((e) => {
            console.info(e)
            this.$message.error('数据加载失败，请稍后重试')
          })
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      modalAddEditVisible: false,
      modalAddEditForm: this.$form.createForm(this),
      modalAddEditFormRules: modalAddEditFormRules,
      geoCascaderList: [],
      downloadExcelState: 0,
      downloadExcelDataset: [],
    }
  },
  mounted() {
    const parameter = {}
    geoCascaderList(parameter)
      .then((res) => {
        if (0 == res.code) {
          this.geoCascaderList = res.result
        }
      })
      .catch((e) => {
        console.info(e)
      })
  },
  methods: {
    modalAddEditLoad(record = null) {
      this.modalAddEditForm.resetFields()
      this.modalAddEditVisible = true
      if (record) {
        this.$nextTick(() => {
          this.modalAddEditForm.setFieldsValue({
            id: record.id,
            fromGeoCode: [record.fromProvinceCode, record.fromCityCode],
            toGeoCode: [record.toProvinceCode, record.toCityCode],
            firstTransportPrice: record.firstTransportPrice,
            freeState: Boolean(record.freeState),
          })
        })
      }
    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    modalAddEditSubmit(e) {
      e.preventDefault()
      let that = this
      this.modalAddEditForm.validateFields((err, values) => {
        if (!err) {
          values.fromProvinceCode = values.fromGeoCode[0]
          values.fromCityCode = values.fromGeoCode[1]
          values.toProvinceCode = values.toGeoCode[0]
          values.toCityCode = values.toGeoCode[1]
          values.freeState = Number(values.freeState)
          delete values.fromGeoCode
          delete values.toGeoCode
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      arteryInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdate(parameter) {
      arteryUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdateFreeState(id, freeState) {
      const parameter = { id: id, freeState: Number(freeState) }
      this.handleUpdate(parameter)
    },
    handleRemove(record) {
      arteryRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
    downloadExcel() {
      if (this.downloadExcelState > 0) {
        return
      }
      this.downloadExcelState = 1
      this.downloadExcelDataset = [['起始地', '目的地', '一级运费单价（元）', '开启干线免费']]

      let parameter = { pageNo: 1, pageSize: 100 }
      this.downloadDataset(parameter)
    },
    downloadDataset(parameter) {
      let that = this
      arteryList(Object.assign(parameter, this.queryParam))
        .then((res) => {
          if (0 == res.code) {
            console.log(res.result)
            const result = res.result.result
            for (let i = 0; i < result.length; i++) {
              const dataset = [
                result[i].fromCityName,
                result[i].toCityName,
                result[i].firstTransportPrice,
                result[i].freeState == 1 ? '开启' : '关闭',
              ]
              that.downloadExcelDataset.push(dataset)
            }
            const pageNo = res.result.pageNo
            const pageSize = res.result.pageSize
            const totalCount = res.result.totalCount
            const totalPage = Math.ceil(totalCount / pageSize)
            if (pageNo >= totalPage) {
              console.log(that.downloadExcelDataset)
              let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset)
              let book = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(book, sheet, '干线表')
              XLSX.writeFile(book, '干线表.xls')
              that.downloadExcelState = 0
              return
            }
            parameter.pageNo = pageNo + 1
            that.downloadDataset(parameter)
          }
        })
        .catch((e) => {
          console.info(e)
          that.downloadExcelState = 0
          that.$message.error('下载失败，请稍后重试')
        })
    },
  },
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100%;
}
.ant-form{
  /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px;
    }
    .percentage{
      border-radius:0 5px 5px 0;
      border-left: 0;
    }
  }
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 70.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-form-item-children {
    justify-content: flex-end;
  }
}
</style>